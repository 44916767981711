import React from 'react';

// data
import { agency as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import SectionTitle from '@components/atoms/SectionTitle';
import Cta from '@components/molecules/Cta';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ItemBullet from '@components/molecules/items/ItemBullet';
import ItemImage from '@components/molecules/items/ItemImage';
import ItemLogo from '@components/molecules/items/ItemLogo';
import ListClients from '@components/organisms/ListClients';
import Panel from '@components/organisms/Panel';

// assets
import imgMessieurs from '@assets/images/partners/messieurs.svg';
import imgSigma_legal from '@assets/images/partners/sigma_legal.svg';
// import imgRamon_pedro from '@assets/images/partners/ramon_pedro.svg';
import imgAnalyser from '@assets/images/analyser.svg';
import imgSimplifier from '@assets/images/simplifier.svg';
import imgAmplifier from '@assets/images/amplifier.svg';
import imgEight from '@assets/images/eight.svg';
import imgStrategy from '@assets/images/ico-strategy.svg';
import imgBranding from '@assets/images/ico-branding.svg';
import imgSolutions from '@assets/images/solutions.svg';

// --------------------------------
// #region Data
// --------------------------------

const language = 'fr';

const pageClass = 'page-agency';

const footerRelatedLinks = [
	{ title: 'L’Équipe', url: '/fr/equipe/' },
	{ title: 'Projets', url: '/fr/projets/' },
];

const servicesList = [
	{
		image: imgStrategy,
		title: 'Stratégie',
		titleTag: 'h3',
		list: [
			'Diagnostic et audit',
			'Positionnement',
			'Recherche',
			'Engagement client',
			'Stratégie d’acquisition',
			'Architecture de marque ',
			'Dénomination',
			'Idéation',
		],
		link: {
			title: 'Découvrir le service',
			url: '/fr/agence-strategie-digitale/',
		},
	},
	{
		image: imgBranding,
		title: 'Branding',
		list: [
			'Logo et identité visuelle',
			'Langage visuel',
			'Direction artistique',
			'Production graphique',
			'Motion design',
			'Personnalité et message de marque',
			'Brand guidelines',
			'Campagne',
		],
		link: {
			title: 'Découvrir le service',
			url: '/fr/agence-branding-suisse/',
		},
	},
	{
		image: imgSolutions,
		title: 'Solutions web',
		list: [
			'eCommerce + Sites internet',
			'Applications iOS + Android',
			'Site d’expérience',
			'Produits et services digitaux',
			'UI et UX design',
			'Design systems + Style guides',
			'Recherche utilisateurs',
			'Prototypage et optimisation',
		],
		link: {
			title: 'Découvrir le service',
			url: '/fr/agence-web-suisse/',
		},
	},
];

const statisticsList = [
	{
		value: '20',
		text:
			'Spécialistes, formant une équipe de talents, qui font de chaque projet une réussite.',
		link: { title: 'Découvrir l’équipe', url: '/fr/equipe/' },
	},
	{
		modifiers: 'award',
		value: '20',
		text:
			'Reconnaissances internationales qui honorent notre démarche innovante et qualitative.',
		awards: [
			'6x Site of The Day – CSS Design Awards',
			'2x Site of The Day – Awwwards',
			'2x Developper Award – Awwwards',
			'8x Honorable Mentions – Awwwards',
		],
	},
	{
		value: '8',
		text:
			'Valeurs qui inspirent notre quotidien. Découvrez la culture de l’agence.',
		link: { title: 'La culture superhuit', url: '/fr/culture/' },
	},
];

// --------------------------------
// #endregion
// --------------------------------

const AgencyPage = (props) => (
	<Layout
		language={language}
		pageClass={pageClass}
		footerRelatedLinks={footerRelatedLinks}
		location={props.location}
		translations={[{ uri: '/en/agency/', lang: 'en' }]}
		seo={{
			description: `Nous sommes superhuit, agence spécialisée en stratégie, branding et solutions web à la recherche de l’augmentation de votre impact`,
		}}
		title="Superhuit, agence de design de marque et d’expérience digitale"
	>
		<HeroPage image={imgEight} title={'We amplify'} />

		<section
			className="grid-content grid text-content"
			aria-label="About"
			data-animation-page
		>
			<h2>
				Nous sommes une agence de design de marque et d’expérience
				digitale
			</h2>
			<p className="col-text">
				Portés par la recherche de progrès, qu’il soit digital ou
				humain, nous cherchons à amplifier l’impact de votre projet ou
				de votre marque comme de vos idées.
			</p>
			<p className="col-text">
				Que votre projet soit très technique ou créatif, nous intégrons
				une démarche de branding pour lui apporter une personnalité
				forte, singulière et intangible.
			</p>
		</section>

		<section className="grid" aria-label="Statistics">
			<Panel
				className={['col-start-2', 'col-end-12']}
				statisticsList={statisticsList}
				data-animation-page
			/>
		</section>

		<section className="grid" aria-label="Services">
			<div className="box text-content text-center" data-animation-page>
				<p className="uptitle">Nos Services</p>
				<div className="list-three-columns">
					{servicesList.map((service, index) => (
						<ItemBullet
							key={`service-${index}`}
							titleTag="h3"
							{...service}
						/>
					))}
				</div>
			</div>
		</section>

		<section className="grid" aria-label="Clients">
			<div className="box text-content text-center" data-animation-page>
				<p className="uptitle">Nous collaborons avec</p>
				<ListClients clientsList={clientsList} />
			</div>
			<Cta
				text={
					<>
						…et si c’était le début d'une
						<br />
						grande aventure ensemble&nbsp;?
					</>
				}
				cta="Mandatez-nous"
				url="#message"
			/>
		</section>

		<section aria-label="Process">
			<SectionTitle title="Nous collaborons pour" />
			<div className="grid" data-animation-page>
				<div className="text-content box">
					<p className="uptitle">Partenaires</p>
					<h2>
						Nous croyons en la force de la
						<br />
						collaboration
					</h2>
					<div className="list-three-columns align-items-center">
						<ItemLogo
							modifiers="small"
							image={{
								src: imgMessieurs,
								alt: 'Messieurs',
							}}
						/>
						<ItemLogo
							modifiers="small"
							image={{
								src: imgSigma_legal,
								alt: 'Sigma',
							}}
							style={{
								justifyContent: 'center',
								// paddingRight: '25%', // hack for visual balance
							}}
							imgStyle={{
								maxHeight: '90px', // override
							}}
						/>
						{/* <ItemLogo
							modifiers="small"
							image={{
								src: imgRamon_pedro,
								alt: 'Ramon+Pedro',
							}}
						/> */}
					</div>
				</div>
			</div>
			<div
				className="process grid-content grid text-content"
				data-animation-page
			>
				<h2 className="title-big">We amplify</h2>
				<p className="col-text">
					<b>Amplifier, amplifier, amplifier.</b> Être porteurs de
					progrès, est notre but ultime. Propulsés par un processus
					d’analyse conduisant à la simplification et permettant
					finalement d’amplifier votre impact.
				</p>
				<div className="list-three-columns list-three-columns--small">
					<ItemImage
						image={imgAnalyser}
						title="Analyser"
						text="pour mieux entreprendre"
					/>
					<ItemImage
						image={imgSimplifier}
						title="Simplifier"
						text="pour mieux valoriser"
					/>
					<ItemImage
						image={imgAmplifier}
						title="Amplifier"
						text="votre impact"
					/>
				</div>
			</div>
		</section>

		<section aria-label="Difference">
			<SectionTitle title="Y&#39;en a point comme nous" />

			<div className="grid" data-animation-page>
				<div className="text-content box">
					<p className="uptitle">Notre différence</p>
					<h2>Y en a point comme nous</h2>
					<ul className="list-two-columns">
						<li>
							<h4>Sur mesure</h4>
							<p>
								Artisans dans l’âme, nous créons des solutions
								sur mesure. Tel un tailleur de haute couture,
								nous imaginons un ensemble adapté à vos besoins
								tout en prenant soin de chaque détail. Cette
								démarche nous a permis de récolter une série de
								prix internationaux.
							</p>
						</li>
						<li>
							<h4>Un projet = une équipe</h4>
							<p>
								De l’analyse à la réalisation, nous formons une
								équipe spécifiquement adaptée à votre projet.
								Elle combine des compétences techniques, design
								et stratégiques favorisant la compréhension
								globale des enjeux et la prise de recul.
							</p>
						</li>
						<li>
							<h4>Proximité</h4>
							<p>
								La rencontre favorise la communication et la
								qualité d’une réalisation. Chaque projet a une
								vision et des défis propres. Pour réussir, nous
								devons comprendre son moteur, et ce moteur…
								c’est vous&nbsp;! C’est pourquoi nous
								investissons dans la relation et, franchement…
								c’est beaucoup plus sympa&nbsp;!
							</p>
						</li>
						<li>
							<h4>Long terme</h4>
							<p>
								Nous aimons les relations qui sont faites pour
								durer. Après le lancement de votre projet, nous
								restons à proximité pour participer à son
								évolution et pour édicter ensemble les
								possibilités d’amélioration. Quand on aime, on
								ne quitte pas.
							</p>
						</li>
						<li>
							<h4>No Bullshit</h4>
							<p>
								De la préanalyse comme au fil des livrables,
								nous désirons discuter de manière ouverte,
								honnête et surtout… sans bullshit. Cette
								approche est essentielle à la réalisation de
								projets percutants.
							</p>
						</li>
						<li>
							<h4>Sans enfermement</h4>
							<p>
								La confiance est notre fil conducteur. Nous nous
								engageons à vous transmettre les sources de
								votre projet permettant de vous assurer la
								pérennité de votre investissement.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<section className="grid" aria-label="Prendre contact" id="message">
			<FormMessage
				title={
					<>
						Comment pouvons-nous
						<br />
						vous aider dans votre projet&nbsp;?
					</>
				}
				label="Message"
				text="Pour nous contacter par email"
				contact="hello@superhuit.ch"
			/>
		</section>
	</Layout>
);

export default AgencyPage;
